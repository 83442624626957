.page-content {
  background-color: $background;
  color: $text;
  padding: 24px;

  summary {
    font-size: 28px;
    cursor: pointer;
    margin-bottom: 24px;
  }
}

.umbanda {
  p {
    margin-bottom: 64px;
  }
}

.calendar {
  .date {
    font-size: 18px;
    margin-bottom: 0px;
  }

  p {
    margin-top: 0px;
  }
}
