.hero-1 {
  margin-top: 50px;
  height: 360px;
  background-color: burlywood;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 24px;

  h1 {
    font-size: 86px;
    margin: 0px;

    @media (max-width: 600px) {
      font-size: 48px;
    }
  }

  p {
    font-size: 24px;
    margin: 0px;
  }

  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
  position: relative;

  .hero-cover {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.2;
  }

  .logo {
    width: 150px;
  }
}

.two-columns {
  display: flex;
  justify-content: space-around;

  .orixa-img {
    max-width: 260px;
    margin-right: 36px;
  }

  @media (max-width: 700px) {
    flex-direction: column;

    .video {
      width: 100%;
    }
  }

  &.infos {
    display: grid;
    grid-template-columns: 1fr 2fr;

    li {
      font-size: 18px;
      cursor: pointer;
      margin-bottom: 8px;
    }

    @media (max-width: 900px) {
      display: block;
    }
  }
}

.previsions-info {
  img {
    max-width: 360px;
    height: 520px;
    margin-right: 36px;
  }

  p {
    margin-top: 0px;
  }
}

@media (max-width: 900px) {
  .previsions-info {
    display: flex;
    flex-direction: column;

    p {
      margin-top: 16px;
    }
  }
  .infos-header {
    flex-direction: column-reverse;
    display: flex;

    img {
      width: 260px;
      height: auto;
    }
  }
}

.numberTable {
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;

  p {
    padding: 8px;
    margin: 0px;
    border-bottom: 1px solid black;
  }
}

.contact {
  margin-top: 48px;
  padding: 48px 0px;
  background-color: $colorThree;
  color: $text;

  p {
    margin: 0px;
  }

  @media (max-width: 900px) {
    div {
      width: 80%;
      margin: auto;
    }
  }
}

.home-header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 900px) {
    // img {
    //   width: 16px;
    // }
    flex-direction: column;

    img {
      width: 120px;
    }
  }
}
