.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  box-shadow: 0px 4px 20px 6px rgb(0 0 0 / 29%);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background-color: $background;
  z-index: 5;

  @media (max-width: 750px) {
    overflow-x: auto;
    width: 100vw;

    .navbar-items {
      overflow: scroll;
      display: flex;

      & > * {
        white-space: nowrap;
      }
    }
  }

  span {
    font-size: 36px;
  }

  a {
    text-decoration: none;
    font-size: 24px;
    color: $text;
    transition: border-bottom 0.1s ease;
    margin-right: 48px;

    &:hover {
      border-bottom: 3px solid $text;
    }
  }
}
