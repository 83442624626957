$text: #4a4e69;
$colorOne: #4a4e69;
$colorTwo: #9a8c98;
$colorThree: #c9ada7;
$background: #f2e9e4;

body {
  background-color: $background;
  font-family: "Roboto", sans-serif;
  margin: 0px;
  font-weight: bold;
}

.page-content {
  width: 75%;
  margin: auto;
  min-height: 50vh;
}

.image-fs-container {
  img {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 85vw;
    max-height: 85vh;
    z-index: 5;
  }
  .image-background {
    z-index: 4;
    position: fixed;
    background-color: rgba(53, 53, 53, 0.651);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  button {
    position: fixed;
    z-index: 5;
    width: 100px;
    height: 100px;
    top: 50%;
    transform: translate(0%, -50%);
    border: 0px;
    cursor: pointer;

    img {
      width: 25px;
    }

    &.left {
      img {
        transform: translate(25%, -50%);
      }
      left: -50px;
      border-radius: 0px 75px 75px 0px;
    }

    &.right {
      img {
        transform: translate(-125%, -50%);
      }
      right: -50px;
      border-radius: 75px 0px 0px 75px;
    }
  }
}
