.photos-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .thumb {
    max-width: 250px;
    height: auto;
    object-fit: contain;
  }
}
